var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-form" }, [
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: {
                    name: "pageNameEN",
                    danger: _vm.errorName && _vm.errors.has("pageNameEN"),
                    "danger-text": _vm.$t("shops.errors.name_is_required")
                  },
                  model: {
                    value: _vm.page.name,
                    callback: function($$v) {
                      _vm.$set(_vm.page, "name", $$v)
                    },
                    expression: "page.name"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "name" + language },
                  model: {
                    value: _vm.page.translations[language].name,
                    callback: function($$v) {
                      _vm.$set(_vm.page.translations[language], "name", $$v)
                    },
                    expression: "page.translations[language].name"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/2" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.description_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-textarea", {
                  staticClass: "w-full",
                  attrs: { name: "pageDescription" + language },
                  model: {
                    value: _vm.page.description,
                    callback: function($$v) {
                      _vm.$set(_vm.page, "description", $$v)
                    },
                    expression: "page.description"
                  }
                })
              : _c("vs-textarea", {
                  staticClass: "w-full",
                  attrs: { name: "pageDescription" + language },
                  model: {
                    value: _vm.page.translations[language].description,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.page.translations[language],
                        "description",
                        $$v
                      )
                    },
                    expression: "page.translations[language].description"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "vx-col 1/3" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-base",
            attrs: { type: "filled" },
            on: {
              click: function($event) {
                return _vm.validateAndSubmit()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("shops.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }