<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <vs-button v-if="showForm" @click="onCreate()">{{
        $t("shops.back")
      }}</vs-button>
      <transition name="fade" moded="out-in">
        <div class="mb-base" v-if="!showForm">
          <data-table
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.page')"
            @update="loadUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <page-form v-model="formData" @submit="submitPageForm" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import PageForm from "@/modules/Shops/Pages/forms/PageForm.vue";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";

export default {
  name: "Pages",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    DataTable,
    PageForm
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/pages", {
      pagesList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      pageById: "getById"
    }),

    tableData() {
      return this.pagesList.map(this.pagesToTableData);
    },
    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
      }
    }
  },

  methods: {
    ...mapActions("shops/pages", {
      loadData: "load",
      removeData: "remove",
      updatePage: "update",
      createPage: "create"
    }),

    pagesToTableData(page) {
      return {
        id: page.id,
        name: page.name,
        created_at: page.created_at,
        updated_at: page.updated_at
      };
    },

    async submitPageForm(page) {
      this.$vs.loading();
      try {
        if (page.id === 0) {
          delete page.id;
          await this.createPage(page);
          this.notifySuccess(
            i18n.t("shops.save"),
            `${i18n.t('shops.page')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
          );
        } else {
          await this.updatePage(page);
          this.notifySuccess(
            i18n.t("shops.updated"),
            `${i18n.t('shops.page')} ${page.name} ${i18n.t('shops.has_been_updated_successfully')}`
          );
        }
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.error.failed_to_save_page"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async loadUpdate(page) {
      const translationsDefault = this.translationsDefault(['name', 'description'])

      this.formData = Object.assign({},this.formData, {
        ...this.pageById(page.id),
        translations: { ...translationsDefault, ...this.pageById(page.id).translations }
      });
      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        name: "",
        translations: this.translationsDefault(['name', 'description']),
        description: "",
        resort_id: 1
      }
    }
  },

  created() {
    this.$store.commit("shops/pages/RESET_RESOURCE");
    this.load();
  }
};
</script>

<style></style>
