<template>
  <div class="page-form">
    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="page.name"
          v-validate="'required'"
          class="w-full"
          name="pageNameEN"
          :danger="errorName && errors.has('pageNameEN')"
          :danger-text="$t('shops.errors.name_is_required')" />
        <vs-input v-else v-model="page.translations[language].name" class="w-full" :name="`name${language}`" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/2">
        <label class="vs-input--label">{{ $t(`shops.description_${language}`) }}</label>
        <vs-textarea v-if="langRequired(language)" v-model="page.description" class="w-full" :name="`pageDescription${language}`" />
        <vs-textarea v-else v-model="page.translations[language].description" class="w-full" :name="`pageDescription${language}`" />
      </div>
    </div>

    <div class="vx-col 1/3">
      <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";

export default {
  name: "PageForm",

  props: {
    value: { type: Object, default: null }
  },

  mixins: [ notifications, shopLanguages ],

  data() {
    return {
      errorName: false,
      page: {}
    };
  },

  methods: {
    validateAndSubmit() {
      const payload = { ...this.languages().reduce((carry, lang) => {
            carry[lang] = this.page.translations[lang]
            return carry
        }, {}), ...this.page}

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("submit", payload);
        } else {
          this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.page_validation_failed"));
          if (!this.page.name) this.errorName = true;
        }
      });
    }
  },
  
  created() {
    this.page = JSON.parse(JSON.stringify(this.value))
  }
};
</script>
